import React from "react"
import ReactTable from "react-table-6"
import PropTypes from "prop-types"
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel
} from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Button from "components/CustomButtons/Button"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { globalSettings } from "variables/general"
import matchSorter from "match-sorter"
import Enums from "tools/Enums.js"
import { observer, inject } from "mobx-react"
import FeedEntryHideModal from "./FeedEntryHideModal"
import { get, put } from "tools/request"

const useStore = component =>
  inject(({ store }) => ({
    organization: store.organizationStore.organization,
    organizations: store.organizationStore.organizations,
    organizationId: store.organizationStore.organizationId,
    account: store.accountStore.account
  }))(observer(component))

class Feed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feed: props.feed || {
        feedLinkUri: "",
        authorname: this.props.organization.authorName || "Data Skrive",
        authoruri:
          this.props.organization.authorURI || "https://dataskrive.com",
        copyright:
          this.props.organization.feedRights ||
          `© ${new Date().getFullYear()} Data Skrive. All rights reserved.`,
        feedLimit: this.props.organization.feedLimit || "",
        feedClassName:
          this.props.organization.feedType ||
          "api_occidental.Models.Core.Feeds.FeedAtom",
        feedStatus: 1 /* 'Testing' status is default */
      },
      editfeed: props.editfeed,
      organization_Id:
        props.feed &&
        props.feed.organization &&
        props.feed.organization.parentId
          ? props.feed.organization.parentId
          : props.feed && props.feed.organization && props.feed.organization.id
          ? props.feed.organization.id
          : this.props.organizationId,
      property:
        props.feed &&
        props.feed.organization &&
        props.feed.organization.parentId
          ? props.feed.organization.id
          : null,
      Permissions: {},
      addProperty: false,
      removeProperty: false
    }
  }

  componentDidMount() {
    if (!this.state.editfeed) {
      this.loadFeedToFeedEntryValidationTypes()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.feed &&
      (nextProps.feed.id !== this.props.feed.id ||
        nextProps.feed.feedModelType_Id !== this.props.feed.feedModelType_Id)
    ) {
      this.setState({
        feed: nextProps.feed
      })
    }
  }

  loadFeedToFeedEntryValidationTypes() {
    get(`${globalSettings.apiBaseUrl}/api/feed/feedtofeedentryvalidationtypes`)
      .then(Response => Response.json())
      .then(feedToFeedEntryValidationTypes => {
        const newFeed = {
          ...this.state.feed,
          feedToFeedEntryValidationTypes:
            feedToFeedEntryValidationTypes.content.types
        }
        // We went to default enable FEV types of:
        // NarrativeBlockSnapshotsErrors, FeedEntryWordCountByPercentage, TriggerErrors, Title,  Categories, SEOSlug, and FeaturedImageJSON
        newFeed.feedToFeedEntryValidationTypes.forEach(type => {
          // We want to set FeedEntryWordCountByPercentage to a Warning severity
          if (type.typeId === 2) {
            type.severityId = 2
          }
        })
        this.setState({ feed: newFeed })
      })
      .catch(err => {
        console.error(
          "Error on GET to /api/feed/feedtofeedentryvalidationtypes",
          err
        )
      })
  }

  handleSubmit() {
    const { feed } = this.state
    this.setState({ saving: true })
    feed.createdBy_Account_Id =
      (this.props.account && this.props.account.id) || 1
    feed.modifiedBy_Account_Id =
      (this.props.account && this.props.account.id) || 1
    feed.organization_Id =
      (typeof this.state.property === "number" ? this.state.property : null) ||
      this.state.feed.organization_Id ||
      this.props.organizationId
    delete feed.property

    feed.feedToFeedEntryValidationTypes.forEach(type => {
      type.feedId = feed.id
    })
    if (typeof feed.organization === "object") {
      feed.organization.id = feed.organization_Id
    }
    const newFeed = {
      ...feed
    }

    let ifediturl = ""
    if (this.state.editfeed) {
      ifediturl = "/update"
    }
    const body = {
      ...newFeed
    }
    put(`${globalSettings.apiBaseUrl}/api/feed${ifediturl}`, null, body)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(() => {
        this.setState({ saving: false })
        this.props.handleSubmit && this.props.handleSubmit()
      })
      .catch(err => {
        console.error(`Error on PUT to /api/feed${ifediturl}`, err)
      })
  }
  render() {
    const { createMode, creatingFeed } = this.props
    const { feed, property, addProperty, removeProperty } = this.state
    if (!feed && !createMode) {
      return "Assembling..."
    }

    const {
      id,
      name,
      feedClassName,
      feedTitle,
      authorname,
      authoruri,
      feedLinkUri,
      feedLimit,
      feedStatus,
      comments,
      copyright,
      feedEntryTTLInMinutes
    } = feed
    const organization_Id = feed.organization_Id
      ? feed.organization_Id
      : feed.organization && feed.organization.parentId
      ? feed.organization.parentId
      : feed.organization && feed.organization.id
      ? feed.organization.id
      : this.props.organizationId

    // Get organizatio list
    const sortedOrganizations =
      this.props.organizations &&
      this.props.organizations.toJS().sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const orgA = a.name.toUpperCase()
        const orgB = b.name.toUpperCase()
        let comparison = 0
        if (orgA > orgB) {
          comparison = 1
        } else if (orgA < orgB) {
          comparison = -1
        }
        return comparison
      })
    let selectedOrgName = ""
    sortedOrganizations.forEach(itm => {
      if (itm.id === organization_Id) {
        selectedOrgName = itm.name
      }
    })

    const allProperties = this.props.allProperties.sort((a, b) => {
      const orgA = a.name.toUpperCase()
      const orgB = b.name.toUpperCase()
      let comparison = 0
      if (orgA > orgB) {
        comparison = 1
      } else if (orgA < orgB) {
        comparison = -1
      }
      return comparison
    })

    let filteredPropeties = {}
    allProperties &&
      allProperties.forEach(item => {
        if (filteredPropeties[item.parentId]) {
          filteredPropeties[item.parentId].push(item)
        } else {
          filteredPropeties[item.parentId] = []
          filteredPropeties[item.parentId].push(item)
        }
      })

    const handleCellChange = name => event => {
      let input = event.target.value
      if (name === "feedEntryTTLInMinutes" || name === "feedLimit") {
        if (input && !input.match(/^\d*$/)) {
          return
        }
        if (input && input > 2147483647) {
          return
        }
      }
      const newFeed = {
        ...this.state.feed,
        [name]: input
      }
      this.setState({ feed: newFeed })
    }

    const handlePropCellChange = name => event => {
      this.setState({ [name]: event.target.value })
    }
    const handleRowEnabledChange = id => () => {
      let feedToFeedEntryValidationTypes =
        this.state.feed.feedToFeedEntryValidationTypes

      feedToFeedEntryValidationTypes.forEach(type => {
        if (type.typeId === id) {
          type.enabled = type.enabled ? false : true
        }
      })

      const newFeed = {
        ...this.state.feed,
        feedToFeedEntryValidationTypes: feedToFeedEntryValidationTypes
      }
      this.setState({ feed: newFeed })
    }

    const handleRowSeverityChange = id => event => {
      let feedToFeedEntryValidationTypes =
        this.state.feed.feedToFeedEntryValidationTypes

      feedToFeedEntryValidationTypes.forEach(type => {
        if (type.typeId === id) {
          type.severityId = event.target.value
        }
      })

      const newFeed = {
        ...this.state.feed,
        feedToFeedEntryValidationTypes: feedToFeedEntryValidationTypes
      }
      this.setState({ feed: newFeed })
    }

    const handleRowParameter1Change = id => event => {
      let feedToFeedEntryValidationTypes =
        this.state.feed.feedToFeedEntryValidationTypes

      feedToFeedEntryValidationTypes.forEach(type => {
        if (type.typeId === id) {
          type.parameter1 = event.target.value
        }
      })

      const newFeed = {
        ...this.state.feed,
        feedToFeedEntryValidationTypes: feedToFeedEntryValidationTypes
      }
      this.setState({ feed: newFeed })
    }

    const handleRowParameter2Change = id => event => {
      let feedToFeedEntryValidationTypes =
        this.state.feed.feedToFeedEntryValidationTypes

      feedToFeedEntryValidationTypes.forEach(type => {
        if (type.typeId === id) {
          type.parameter2 = event.target.value
        }
      })

      const newFeed = {
        ...this.state.feed,
        feedToFeedEntryValidationTypes: feedToFeedEntryValidationTypes
      }
      this.setState({ feed: newFeed })
    }
    const haveProperty =
      filteredPropeties &&
      filteredPropeties[organization_Id] &&
      filteredPropeties[organization_Id].length > 0

    return (
      <React.Fragment>
        <GridContainer>
          {this.state.editfeed && (
            <GridItem xs={12} sm={1}>
              <TextField
                disabled={true}
                type="text"
                id="feedid"
                label="Feed ID"
                fullWidth
                value={id || ""}
                onChange={handleCellChange("id")}
                margin="normal"
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={4}>
            <TextField
              type="text"
              id="name"
              label="Name"
              fullWidth
              value={name || ""}
              onChange={handleCellChange("name")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <TextField
              type="text"
              id="feedtitle"
              label="Title"
              fullWidth
              value={feedTitle || ""}
              onChange={handleCellChange("feedTitle")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} sm={2}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Feed Output Type</InputLabel>
              <Select
                fullWidth={false}
                label="Feed Output Type"
                type="select"
                name="feedclassname"
                id="feedclassname"
                value={
                  feedClassName || "api_occidental.Models.Core.Feeds.FeedAtom"
                }
                onChange={handleCellChange("feedClassName")}
              >
                <MenuItem
                  key="FeedRSS"
                  value="api_occidental.Models.Core.Feeds.FeedRSS"
                >
                  FeedRSS
                </MenuItem>
                <MenuItem
                  key="FeedAtom"
                  value="api_occidental.Models.Core.Feeds.FeedAtom"
                >
                  FeedAtom
                </MenuItem>
                <MenuItem
                  key="FeedAPAtom"
                  value="api_occidental.Models.Core.Feeds.FeedAPAtom"
                >
                  FeedAPAtom
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Organization</InputLabel>
              <Select
                fullWidth={false}
                label="Organization"
                type="select"
                name="organization_Id"
                id="organization_Id"
                value={organization_Id || this.props.organizationId || ""}
                onChange={handleCellChange("organization_Id")}
                disabled={creatingFeed}
              >
                {sortedOrganizations &&
                  sortedOrganizations.map(organization => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          {(property || addProperty) && haveProperty && (
            <GridItem xs={12} sm={3}>
              <FormControl margin="normal" fullWidth>
                <InputLabel>
                  Property (Assign to one of {selectedOrgName}'s properties?)
                </InputLabel>
                <Select
                  fullWidth={false}
                  type="select"
                  name="property"
                  id="property"
                  value={property || selectedOrgName}
                  onChange={handlePropCellChange("property")}
                >
                  <MenuItem key={selectedOrgName} value={selectedOrgName}>
                    {selectedOrgName}
                  </MenuItem>
                  {filteredPropeties[organization_Id].map(property => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          )}
          {!property && haveProperty && (
            <GridItem xs={12} sm={2} style={{ padding: "25px 0 0 0" }}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    fontSize="small"
                    checked={this.state.addProperty || false}
                    onChange={event => {
                      this.setState({
                        addProperty: event.target.checked
                      })
                    }}
                  />
                }
                label="Add Property?"
              />
            </GridItem>
          )}
          {property && (
            <GridItem xs={12} sm={2} style={{ padding: "25px 0 0 0" }}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    fontSize="small"
                    checked={removeProperty || false}
                    onChange={() => {
                      this.setState({
                        property: null
                      })
                    }}
                  />
                }
                label="Remove Property?"
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={2}>
            <Tooltip title="Leave blank for default (150)" placement="top">
              <TextField
                type="number"
                id="feedLimit"
                label="Feed Entries Limit"
                value={feedLimit || ""}
                onChange={handleCellChange("feedLimit")}
                margin="normal"
              />
            </Tooltip>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Tooltip
                title="Set when the feed entries will be hidden from the feed."
                placement="top"
              >
                <TextField
                  fullWidth
                  type="number"
                  id="feedEntryTTLInSeconds"
                  label="Hide Feed Entries After (min)"
                  value={feedEntryTTLInMinutes}
                  onChange={handleCellChange("feedEntryTTLInMinutes")}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                />
              </Tooltip>
              <FeedEntryHideModal />
            </div>
          </GridItem>
          {this.state.editfeed && (
            <GridItem xs={12} sm={2} style={{ padding: "25px 0 0 0" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.feed.isArchived || false}
                    onChange={event => {
                      const newFeed = {
                        ...this.state.feed,
                        isArchived: event.target.checked
                      }
                      this.setState({ feed: newFeed })
                    }}
                  />
                }
                label="Is Archived?"
              />
            </GridItem>
          )}
        </GridContainer>
        <GridContainer>
          {this.state.editfeed && (
            <GridItem xs={12} sm={6}>
              <TextField
                disabled={true}
                type="text"
                id="feedlinkuri"
                label="Feed Link"
                fullWidth
                value={feedLinkUri || ""}
                onChange={handleCellChange("feedlinkuri")}
                margin="normal"
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={2}>
            <TextField
              type="text"
              id="authorname"
              label="Authorname"
              fullWidth
              value={authorname || "Data Skrive"}
              onChange={handleCellChange("authorname")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <TextField
              type="text"
              id="authoruri"
              label="Author Uri"
              fullWidth
              value={authoruri || "https://dataskrive.com"}
              onChange={handleCellChange("authoruri")}
              margin="normal"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <FormControl margin="normal" fullWidth>
              <InputLabel>Feed Status</InputLabel>
              <Select
                fullWidth={false}
                label="Feed Status"
                type="select"
                name="feedstatus"
                id="feedstatus"
                value={feedStatus}
                onChange={handleCellChange("feedStatus")}
              >
                <MenuItem key="Active" value={Enums.FeedStatus.ACTIVE}>
                  Active
                </MenuItem>
                <MenuItem key="Inactive" value={Enums.FeedStatus.INACTIVE}>
                  Inactive
                </MenuItem>
                <MenuItem key="Validate" value={Enums.FeedStatus.VALIDATE}>
                  Validate
                </MenuItem>
                <MenuItem
                  key="ValidateInternal"
                  value={Enums.FeedStatus.VALIDATEINTERNAL}
                >
                  Validate - Internal Only
                </MenuItem>
                <MenuItem
                  key="PriorityEvent"
                  value={Enums.FeedStatus.PRIORITYEVENT}
                >
                  Priority Event
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={5}>
            <TextField
              type="text"
              id="comments"
              label="Comments"
              fullWidth
              multiline
              value={comments || ""}
              onChange={handleCellChange("comments")}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <TextField
              type="text"
              id="feedrights"
              label="Feed Rights"
              fullWidth
              multiline
              value={
                copyright ||
                `© ${new Date().getFullYear()} Data Skrive. All rights reserved.`
              }
              onChange={handleCellChange("copyright")}
              margin="normal"
            />
          </GridItem>
        </GridContainer>
        <h4 style={{ marginTop: "30px" }}>Feed Entry Validation Types</h4>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "10px",
            fontWeight: "300",
            fontSize: "1em"
          }}
        >
          Check the box to enable the Feed Entry Validation Type:
        </div>
        <Grid>
          <ReactTable
            data={this.state.feed.feedToFeedEntryValidationTypes}
            columns={[
              {
                accessor: "enabled",
                Cell: ({ row }) => (
                  <input
                    type="checkbox"
                    className="checkbox"
                    style={{ marginLeft: "6px", marginTop: "3px" }}
                    checked={row._original.enabled}
                    onChange={handleRowEnabledChange(row._original.typeId)}
                  />
                ),
                width: 40
              },
              {
                Header: "Id",
                accessor: "typeId",
                Cell: cell => (
                  <div style={{ textAlign: "center" }}>{cell.value}</div>
                ),
                width: 50
              },
              {
                Header: "Name",
                accessor: "name",
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["Name"],
                    threshold: matchSorter.rankings.CONTAINS
                  }),
                filterAll: true,
                width: 350
              },
              {
                Header: "Severity",
                accessor: "severityId",
                Cell: ({ row }) => (
                  <FormControl margin="normal">
                    <Select
                      type="select"
                      size="small"
                      name="severityId"
                      id="severityid"
                      style={{ width: "110px" }}
                      value={row._original.severityId}
                      onChange={handleRowSeverityChange(row._original.typeId)}
                    >
                      <MenuItem key="1" value="1">
                        Error
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Warning
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Info
                      </MenuItem>
                    </Select>
                  </FormControl>
                ),
                width: 150
              },
              {
                Header: "Parameter 1",
                accessor: "parameter1",
                Cell: ({ row }) => (
                  <Tooltip
                    title={
                      (row._original.typeId === 1 && "Not used") ||
                      (row._original.typeId === 3 && "Not used") ||
                      (row._original.typeId === 2 && "Defaults to 25%") ||
                      (row._original.typeId > 3 &&
                        "Default checks for empty or null")
                    }
                    placement="top"
                  >
                    <TextField
                      type="text"
                      id="parameter1"
                      label={
                        (row._original.typeId === 1 && "N/A") ||
                        (row._original.typeId === 3 && "N/A") ||
                        (row._original.typeId === 2 && "Whole Number") ||
                        (row._original.typeId > 3 && "Min char length")
                      }
                      style={{ width: "150px" }}
                      value={row._original.parameter1 || ""}
                      onChange={handleRowParameter1Change(row._original.typeId)}
                      margin="normal"
                    />
                  </Tooltip>
                )
              },
              {
                Header: "Parameter 2",
                accessor: "parameter2",
                Cell: ({ row }) => (
                  <Tooltip
                    title={
                      (row._original.typeId < 4 && "Not used") ||
                      (row._original.typeId > 3 && "No default max check")
                    }
                    placement="top"
                  >
                    <TextField
                      type="text"
                      id="parameter2"
                      label={
                        (row._original.typeId < 4 && "N/A") ||
                        (row._original.typeId > 3 && "Max char length")
                      }
                      style={{ width: "150px" }}
                      value={row._original.parameter2 || ""}
                      onChange={handleRowParameter2Change(row._original.typeId)}
                    />
                  </Tooltip>
                )
              }
            ]}
            filterable={false}
            showPaginationTop={false}
            showPaginationBottom={false}
            defaultPageSize={30}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: "id",
                desc: false
              }
            ]}
            style={{
              height: "800px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
          />
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          <Button
            style={{ margin: "10px 10px 10px 10px" }}
            size="sm"
            variant="contained"
            onClick={() => this.props.handleCancel && this.props.handleCancel()}
          >
            Cancel
          </Button>
          <Button
            style={{ margin: "10px 10px 10px 10px" }}
            size="sm"
            variant="contained"
            color="primary"
            onClick={() => this.handleSubmit()}
          >
            Save
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

Feed.propTypes = {
  // Where the user to be redirected on clicking the avatar
  feed: PropTypes.object
}

export default useStore(Feed)
