import React from "react"
import Helpers from "tools/Helpers.js"
import TimeAgo from "react-timeago"
import Enums from "tools/Enums.js"
import { Cookies } from "tools/storage"
import { globalSettings } from "variables/general"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CookieExpiration: {
    OneDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    OneWeek: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    OneMonth: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
    OneYear: new Date(new Date().getTime() + 365 * 30 * 24 * 60 * 60 * 1000)
  },
  sortByCreatedOnDateDesc: (a, b) =>
    new Date(b.auditInfo.createdOn) - new Date(a.auditInfo.createdOn),
  sortByFriendlyName: (a, b) => {
    // Use toUpperCase() to ignore character casing
    const stringA = a.friendlyName ? a.friendlyName.toUpperCase() : ""
    const stringB = b.friendlyName ? b.friendlyName.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  },
  sortByName: (a, b) => {
    // Use toUpperCase() to ignore character casing
    const stringA = a.name ? a.name.toUpperCase() : ""
    const stringB = b.name ? b.name.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  },
  sortById: (a, b) => {
    if (!a || !b) {
      return 0
    }
    let comparison = 0
    if (a.id > b.id) {
      comparison = 1
    } else if (a.id < b.id) {
      comparison = -1
    }
    return comparison
  },
  sortByVariantName: (a, b) => {
    // Use toUpperCase() to ignore character casing
    const stringA = a.variantName ? a.variantName.toUpperCase() : ""
    const stringB = b.variantName ? b.variantName.toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  },
  sortMapByName: (a, b) => {
    const stringA = a.get("name") ? a.get("name").toUpperCase() : ""
    const stringB = b.get("name") ? b.get("name").toUpperCase() : ""
    let comparison = 0
    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  },
  shortDateTimeinPacificTimeZone: datetimeString => {
    const dateTimeOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "America/Los_Angeles",
      timeZoneName: "short"
    }
    if (!datetimeString) {
      return null
    }
    return new Intl.DateTimeFormat("en-US", dateTimeOptions).format(
      new Date(datetimeString)
    )
  },
  prettyDateTimeinPacificTimeZone: datetimeString => {
    const dateTimeOptions = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "America/Los_Angeles",
      timeZoneName: "short"
    }
    if (!datetimeString) {
      return null
    }
    return new Intl.DateTimeFormat("en-US", dateTimeOptions).format(
      new Date(datetimeString)
    )
  },

  prettyDateTimeinUTCTimeZone: datetimeString => {
    const dateTimeOptions = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "UTC",
      timeZoneName: "short"
    }
    if (!datetimeString) {
      return null
    }
    return new Intl.DateTimeFormat("en-US", dateTimeOptions).format(
      new Date(datetimeString)
    )
  },

  prettyDateTimeinDefaultTimeZone: datetimeString => {
    const dateTimeOptions = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true
    }
    if (!datetimeString) {
      return null
    }
    return new Intl.DateTimeFormat("en-US", dateTimeOptions).format(
      new Date(datetimeString)
    )
  },

  titleCase: str => {
    if (!str) {
      return ""
    }
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  },

  titleCaseSlug: str => {
    if (!str) {
      return ""
    }
    return str
      .toLowerCase()
      .split("_")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  },

  determineTheCorrectOrganization(
    isLibrariesOnly,
    orgIdFromState,
    orgIdFromProps
  ) {
    let cookies = new Cookies()
    const last = cookies.get("lastorg")
    if (
      !isLibrariesOnly &&
      orgIdFromState === Enums.Organizations.Libraries &&
      last
    ) {
      cookies.set("currentorg", last)
      return last
    } else if (orgIdFromProps !== Enums.Organizations.Libraries) {
      return orgIdFromProps
    } else if (last) {
      return last
    } else {
      return ""
    }
  },

  didWeNavigateToLibraryEditor: narrative => {
    const orgMenu = document.getElementById("OrganizationDropdown")
    const propMenu = document.getElementById("property")
    const display = narrative?.isLibrary ? "none" : "inline-block"
    if (orgMenu) {
      orgMenu.style.display = display
      if (propMenu) {
        propMenu.style.display = display
      }
    }
  },

  italicizeInverseTriggers: (str, myClassName, myStyle, myInverseTrigger) => {
    if (!str) {
      return <div />
    }
    if (str.indexOf("!") === -1) {
      return (
        <div style={{ myStyle }} className={myClassName}>
          {str}
        </div>
      )
    }
    const idInfo = str.match(/\(.*\)/)
    const triggerInfo = str.split(/\(.*\)/)
    const hasInverseTrigger = str.match(/!/)
    if (hasInverseTrigger) {
      let triggers
      if (triggerInfo && triggerInfo[1]) {
        triggers = triggerInfo[1].split(",")
      } else {
        triggers = triggerInfo[0].split(",")
      }
      return (
        <div className={`${myClassName} ${myStyle}`}>
          {idInfo && idInfo[0] ? `${idInfo[0]} ` : ""}
          {triggers.map((trg, index) =>
            index ? (
              !trg.trim().match(/^!/) ? (
                <>, {trg.trim()}</>
              ) : (
                <>
                  , <span className={myInverseTrigger}>!</span>
                  {trg.trim().replace(/^!/, "")}
                </>
              )
            ) : !trg.trim().match(/^!/) ? (
              <>{trg.trim()}</>
            ) : (
              <>
                <span className={myInverseTrigger}>!</span>
                {trg.trim().replace(/^!/, "")}
              </>
            )
          )}
        </div>
      )
    } else {
      return <div className={`${myClassName} ${myStyle}`}>{str}</div>
    }
  },
  stripHtml: html => {
    // Create a new div element
    let temporalDivElement = document.createElement("div")
    // Set the HTML content with the providen
    if (html !== null) {
      html = html.replace(/apxh:/gi, "")
      html = html.replace(/<\/p>/gi, "</p>\n")
      html = html.replace(/<\/li>/gi, "</li>\n")
      html = html.replace(/<\/h1>/gi, "</h1>\n")
      html = html.replace(/<\/h2>/gi, "</h2>\n")
      html = html.replace(/<\/h3>/gi, "</h3>\n")
      html = html.replace(/<\/h4>/gi, "</h4>\n")
      html = html.replace(/<\/h5>/gi, "</h5>\n")
    }
    temporalDivElement.innerHTML = html
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.innerText || temporalDivElement.textContent || ""
  },
  loadPublishDelayOptions: () =>
    // We store delay in the unit of seconds
    [
      {
        key: -1,
        value: "Use default"
      },
      {
        key: 0,
        value: "No delay"
      },
      {
        key: 60,
        value: "1 minute"
      },
      {
        key: 300,
        value: "5 minutes"
      },
      {
        key: 600,
        value: "10 minutes"
      },
      {
        key: 900,
        value: "15 minutes"
      },
      {
        key: 1200,
        value: "30 minutes"
      },
      {
        key: 3600,
        value: "1 hour"
      },
      {
        key: 5400,
        value: "90 minutes"
      },
      {
        key: 7200,
        value: "2 hours"
      }
    ],
  convertFeedStatusIdToStatusName: feedStatusId => {
    let statusName = "N/A"
    switch (feedStatusId) {
      case Enums.FeedStatus.VALIDATE:
        statusName = "Validate"
        break
      case Enums.FeedStatus.VALIDATEINTERNAL:
        statusName = "Validate - Internal Only"
        break
      case Enums.FeedStatus.INACTIVE:
        statusName = "Inactive"
        break
      case Enums.FeedStatus.ACTIVE:
        statusName = "Active"
        break
      case Enums.FeedStatus.PRIORITYEVENT:
        statusName = "Priority Event"
        break
      default:
    }
    return statusName
  },
  convertWebhookStatusIdToStatusName: webhookStatusId => {
    let statusName = "N/A"
    switch (webhookStatusId) {
      case Enums.WebhookStatus.VALIDATE:
        statusName = "Validate"
        break
      case Enums.WebhookStatus.VALIDATEINTERNAL:
        statusName = "Validate - Internal Only"
        break
      case Enums.WebhookStatus.INACTIVE:
        statusName = "Inactive"
        break
      case Enums.WebhookStatus.ACTIVE:
        statusName = "Active"
        break
      case Enums.WebhookStatus.PRIORITYEVENT:
        statusName = "Priority Event"
        break
      default:
    }
    return statusName
  },
  convertSentenceStatusIdToStatusName: sentenceStatusId => {
    let statusName = "N/A"
    switch (sentenceStatusId) {
      case Enums.SentenceStatus.DRAFT:
        statusName = "Draft"
        break
      case Enums.SentenceStatus.REVIEW:
        statusName = "Review"
        break
      case Enums.SentenceStatus.PUBLISHED:
        statusName = "Published"
        break
      case Enums.SentenceStatus.PLANNED:
        statusName = "Planned"
        break
      case Enums.SentenceStatus.SAMPLE:
      default:
        statusName = "Sample"
        break
    }
    return statusName
  },
  convertOwnerActionIdToActionName: ownerActionId => {
    let action = "None"
    switch (ownerActionId) {
      case Enums.OwnerActions.CREATE:
        action = "Create"
        break
      case Enums.OwnerActions.REMOVEOWNER:
        action = "Remove Owner"
        break
      case Enums.OwnerActions.COPYEDIT:
        action = "Copy Edit"
        break
      case Enums.OwnerActions.WRITE:
        action = "Write"
        break
      default:
    }
    return action
  },
  convertBlocksStatusIdToStatusName: blockStatusId => {
    let statusName = "N/A"
    switch (blockStatusId) {
      case Enums.BlockStatus.INACTIVE:
        statusName = "Inactive"
        break
      case Enums.BlockStatus.REVIEW:
        statusName = "In Review"
        break
      case Enums.BlockStatus.ACTIVE:
        statusName = "Active"
        break
      default:
    }
    return statusName
  },
  convertNarrativeStatusIdToStatusName: narrativeStatusId => {
    let statusName = "N/A"
    switch (narrativeStatusId) {
      case Enums.NarrativeStatus.INACTIVE:
        statusName = "Inactive"
        break
      case Enums.NarrativeStatus.VALIDATE:
        statusName = "Validate"
        break
      case Enums.NarrativeStatus.ACTIVE:
        statusName = "Active"
        break
      default:
    }
    return statusName
  },
  convertContentTypeIdToName: contentTypeId => {
    let contentTypeName = "N/A"
    switch (contentTypeId) {
      case Enums.ContentType.NONE:
        contentTypeName = "N/A"
        break
      case Enums.ContentType.FANTASYFOOTBALLPLAYER:
        contentTypeName = "Fantasy Football Player"
        break
      case Enums.ContentType.QUERY:
        contentTypeName = "Query"
        break
      case Enums.ContentType.SNAPSHOT:
        contentTypeName = "Snapshot"
        break
      case Enums.ContentType.MATCHPREVIEW:
        contentTypeName = "Match Preview"
        break
      case Enums.ContentType.MATCHRECAP:
        contentTypeName = "Match Recap"
        break
      case Enums.ContentType.COMPETITION:
        contentTypeName = "Competition"
        break
      case Enums.ContentType.SPORT:
        contentTypeName = "Sport"
        break
      case Enums.ContentType.LEAGUE:
        contentTypeName = "Leauge"
        break
      case Enums.ContentType.CONFERENCE:
        contentTypeName = "Conference"
        break
      case Enums.ContentType.DIVISION:
        contentTypeName = "Division"
        break
      case Enums.ContentType.SUBDIVSION:
        contentTypeName = "Subdivsion"
        break
      case Enums.ContentType.TEAM:
        contentTypeName = "Team"
        break
      case Enums.ContentType.OTHER:
        contentTypeName = "Other"
        break
      case Enums.ContentType.LEVEL:
        contentTypeName = "Level"
        break
      case Enums.ContentType.REGION:
        contentTypeName = "Region"
        break
      case Enums.ContentType.PERSON:
        contentTypeName = "Person"
        break
      case Enums.ContentType.CITY:
        contentTypeName = "City"
        break
      case Enums.ContentType.SCHOOL:
        contentTypeName = "School"
        break
      case Enums.ContentType.YEAR:
        contentTypeName = "Year"
        break
      case Enums.ContentType.GENDER:
        contentTypeName = "Gender"
        break
      case Enums.ContentType.COUNTRY:
        contentTypeName = "Country"
        break
      case Enums.ContentType.MEDIA:
        contentTypeName = "Media"
        break
      case Enums.ContentType.AUDIO:
        contentTypeName = "Audio"
        break
      case Enums.ContentType.FILEASSET:
        contentTypeName = "File Asset"
        break
      case Enums.ContentType.RANKING:
        contentTypeName = "Ranking"
        break
      case Enums.ContentType.POST:
        contentTypeName = "Post"
        break
      case Enums.ContentType.BUSINESSVERTICAL:
        contentTypeName = "Business Vertical"
        break
      case Enums.ContentType.MODELDATASNAPSHOT:
        contentTypeName = "Model Data Snapshot"
        break
      default:
    }
    return contentTypeName
  },
  buildCustomStyleForFeedStatus: feedStatusId => {
    switch (feedStatusId) {
      case Enums.FeedStatus.INACTIVE:
        return {
          background: "#ccc"
        }
      case Enums.FeedStatus.VALIDATE:
        return {
          background: "#ffd900"
        }
      case Enums.FeedStatus.VALIDATEINTERNAL:
        return {
          background: "#f97f00"
        }
      case Enums.FeedStatus.PRIORITYEVENT:
        return {
          background: "#C3B1E1"
        }
      case Enums.FeedStatus.ACTIVE: // (don't change anything visually)
      default:
        return {}
    }
  },
  buildCustomStyleForWebhookStatus: webhookStatusId => {
    switch (webhookStatusId) {
      case Enums.FeedStatus.INACTIVE:
        return {
          background: "#ccc"
        }
      case Enums.FeedStatus.VALIDATE:
        return {
          background: "#ffd900"
        }
      case Enums.FeedStatus.VALIDATEINTERNAL:
        return {
          background: "#f97f00"
        }
      case Enums.FeedStatus.PRIORITYEVENT:
        return {
          background: "#C3B1E1"
        }
      case Enums.FeedStatus.ACTIVE: // (don't change anything visually)
      default:
        return {}
    }
  },
  buildCustomStyleForNarrativeStatus: narrativeStatusId => {
    switch (narrativeStatusId) {
      case Enums.NarrativeStatus.INACTIVE:
        return {
          background: "#ccc"
        }
      case Enums.NarrativeStatus.VALIDATE:
        return {
          background: "#ffd900"
        }
      case Enums.NarrativeStatus.ACTIVE: // (don't change anything visually)
      default:
        return {}
    }
  },
  renderAuditInfoCell: cell => {
    let created
    let modified
    created = cell.original.auditInfo ? (
      <span>
        created{" "}
        <TimeAgo
          date={cell.original.auditInfo.createdOn}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.original.auditInfo.createdOn
          )}
        />{" "}
        by {cell.original.auditInfo.creator.username}
      </span>
    ) : (
      ""
    )
    modified = cell.original.auditInfo ? (
      <span>
        modified{" "}
        <TimeAgo
          date={cell.original.auditInfo.modifiedOn}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.original.auditInfo.modifiedOn
          )}
        />{" "}
        by {cell.original.auditInfo.modifier.username}
        <br />
      </span>
    ) : (
      ""
    )
    return (
      <div>
        {modified}
        {created}
      </div>
    )
  },
  parseNestedObjectSelector: (obj, selector) => {
    //You cannot pass a "nested selector" into an object, ie obj[selector] where selector is "first.second.third".
    //You have to add each selector once at a time to the base object, so I just split the selector and then iterate through each "key"
    if (typeof obj !== "object") {
      return null
    }
    let nesting = selector.split(".")
    nesting.forEach(itm => {
      obj = obj[itm] || obj
    })
    return obj
  },
  renderFriendlyTeamGroupName: teamGroupId => {
    switch (parseInt(teamGroupId)) {
      case Enums.TeamGroups.NFL:
        return "NFL (National Football Leauge)"
      case Enums.TeamGroups.WNBA:
        return "WNBA (Women's National Basketball Assocation)"
      case Enums.TeamGroups.NBA:
        return "NBA (National Basketball Assocation)"
      case Enums.TeamGroups.NHL:
        return "NHL (National Hockey League)"
      case Enums.TeamGroups.LaLiga:
        return "La Liga (Spain)"
      case Enums.TeamGroups.EPL:
        return "EPL (English Premier League)"
      case Enums.TeamGroups.UEFAChampionsLeague:
        return "Champions League (Europe)"
      case Enums.TeamGroups.SerieA:
        return "Serie A (Italy)"
      case Enums.TeamGroups.Bundesliga:
        return "Bundesliga (German)"
      case Enums.TeamGroups.Eredivisie:
        return "Eredivisie (Netherlands)"
      case Enums.TeamGroups.LaLigaMX:
        return "La Liga MX (Mexico)"
      case Enums.TeamGroups.Ligue1:
        return "Ligue 1 (France)"
      case Enums.TeamGroups.MLS:
        return "MLS (Major League Soccer)"
      case Enums.TeamGroups.BrasileiroSerieA:
        return "Serie A (Brazil)"
      case Enums.TeamGroups.PrimeiraLiga:
        return "Primeira Liga (Portugal)"
      case Enums.TeamGroups.SuperLig:
        return "Super Lig (Turkey)"
      case Enums.TeamGroups.SuperLiga:
        return "SuperLiga (Argentina)"
      case Enums.TeamGroups.MLB:
        return "MLB (Major League Baseball)"
      case Enums.TeamGroups.MensFIFAWorldCup:
        return "Men's FIFA World Cup"
      case Enums.TeamGroups.WomensFIFAWorldCup:
        return "Women's FIFA World Cup"
      case Enums.TeamGroups.NCAAF:
        return "NCAA Football"
      case Enums.TeamGroups.NCAAB:
        return "NCAA Men's Basketball"
      case Enums.TeamGroups.NCAABW:
        return "NCAA Women's Basketball"
      case Enums.TeamGroups.NCAAHockey:
        return "NCAA Hockey"
      case Enums.TeamGroups.ATP:
        return "ATP (Association of Tennis Players)"
      case Enums.TeamGroups.ITF:
        return "ITF (International Tennis Foundation)"
      case Enums.TeamGroups.PGA:
        return "PGA (Professional Golfers Assocation)"
      case Enums.TeamGroups.LPGA:
        return "LPGA (Ladies Professional Golfers Association)"
      case Enums.TeamGroups.WTA:
        return "WTA (Women's Tennis Association)"
      case Enums.TeamGroups.LIVTour:
        return "LIV (LIV Golf Tour)"
      case Enums.TeamGroups.ProfessionalGolfers:
        return "Professional Golfers"
      case Enums.TeamGroups.ProfessionalTennisPlayers:
        return "Professional Tennis Players"
      case Enums.TeamGroups.NASCAR:
        return "NASCAR"
      case Enums.TeamGroups.NWSL:
        return "NWSL (USA)"
      case Enums.TeamGroups.Hidden:
        return ""
      case Enums.TeamGroups.NotApplicable:
        return "Not applicable"
      default:
        return "None"
    }
  },
  renderFriendlyGeographicPlaceGroupName: geographicPlaceContentTypeId => {
    switch (geographicPlaceContentTypeId) {
      case Enums.ContentType.CITY:
        return "Cities"
      case Enums.ContentType.REGION:
        return "States/Provinces/Regions"
      case Enums.ContentType.COUNTRY:
      default:
        return "Countries"
    }
  },
  renderFriendlyGenderName: genderId => {
    switch (genderId) {
      case Enums.Gender.MALE:
        return "Men"
      case Enums.Gender.FEMALE:
      default:
        return "Women"
    }
  },
  renderFriendlyDeliveryTypeName: deliveryTypeId => {
    switch (deliveryTypeId) {
      case Enums.DeliveryType.BATCH:
        return "BATCH"
      case Enums.DeliveryType.INDIVIDUAL:
        return "INDIVIDUAL"
      default:
        return "None"
    }
  },
  renderFriendlyWebhookStatusName: webhookStatusId => {
    switch (webhookStatusId) {
      case Enums.WebhookStatus.INACTIVE:
        return "Inactive"
      case Enums.WebhookStatus.VALIDATE:
        return "Validate"
      case Enums.WebhookStatus.VALIDATEINTERNAL:
        return "Validate Internal"
      case Enums.WebhookStatus.PRIORITYEVENT:
        return "Priority Event"
      case Enums.WebhookStatus.ACTIVE:
      default:
        return "Active"
    }
  },
  doTheseTwoArraysOfObjectsMatch: (arr1, arr2, synchronizeKeyValue) => {
    //Checks to see if the arrays match, there are 2 caveats:
    //1 - arr1 is the "dominant" array, which means it only matches the properties in that array of Objects
    //so if in arr1 each object has just "id" and "name", while arr2 it has "id", "name", and "date", the date is ignored for the match
    //But if its reversed - arr1 has the extra "date" - they will never match.  SOOOO make sure you account for this.
    //2 - passing in "synchronizeKeyValue" allows you to account for when the arrays have content "out of order" but potentiall still matching
    //If it exists, it runs the "synchronizeKeyValue" through arr1 and finds the corresponding location in arr2 and creates a version of arr2 (newArray) for testing
    if (!arr2 || !arr1) {
      return false
    } else if (arr1.length !== arr2.length) {
      return false
    } else if (arr1.length === 0 && arr2.length === 0) {
      return true
    }
    let match = true
    if (synchronizeKeyValue && arr1[0][synchronizeKeyValue]) {
      let newArray = []
      arr1.forEach(itm => {
        let foundIndex = itm[synchronizeKeyValue] || -1
        if (foundIndex > -1) {
          arr2.forEach(itm2 => {
            let foundIndex2 = itm2[synchronizeKeyValue]
            if (foundIndex === foundIndex2) {
              newArray.push(itm2)
            }
          })
        } else {
          match = false
        }
      })
      arr1.forEach((itm, i) => {
        Object.keys(itm).forEach(function (key) {
          if (itm[key] !== (newArray[i] && newArray[i][key])) {
            match = false
          }
        })
      })
    } else {
      arr1.forEach((itm, i) => {
        Object.keys(itm).forEach(function (key) {
          if (itm[key] !== (arr2[i] && arr2[i][key])) {
            match = false
          }
        })
      })
    }
    return match
  },
  removeDuplicatesInArraysOfObjects: (arr1, keys) => {
    //Removes duplicates in arr1, based on the "keys" array which contains the properties you want to match to determine duplication
    //We start at the bottom of arr1, and if we find a "match", we remove the bottom element and break to the next element
    for (let i = arr1.length - 1; i > -1; i -= 1) {
      for (let j = arr1.length - 1; j > -1; j -= 1) {
        if (i !== j) {
          let match = true
          keys.forEach(key => {
            match = arr1[j][key] !== arr1[i][key] ? false : match
          })
          if (match) {
            arr1.splice(i, 1)
            break
          }
        }
      }
    }
    return arr1
  },
  removeDuplicatesInArraysOfObjects_NumberMatches: (arr1, keys) => {
    //Go thorugh object of arrays ond resave only entries where the key vaulues do not match
    const foundKeys = []
    const uniqueValues = []
    arr1.forEach(itm => {
      let matches = 0
      foundKeys.forEach(flt => {
        let found = true
        keys.forEach(key => {
          if (Number(flt[key]) !== Number(itm[key])) {
            found = false
          }
        })
        matches = found ? matches + 1 : matches
      })
      if (matches === 0) {
        uniqueValues.push(itm)
        foundKeys.push({ narrativeId: itm.narrativeId, feedId: itm.feedId })
      }
    })
    return uniqueValues
  },
  parseJSON: json => {
    let parsedJSON = {
      json,
      isValidJSON: false,
      invalidJSONMessage: "",
      atPosition: 0
    }
    try {
      if (parsedJSON.json.indexOf("Preview String is Empty") === -1) {
        JSON.parse(parsedJSON.json)
      }
      parsedJSON.invalidJSONMessage = "JSON is valid."
      parsedJSON.isValidJSON = true
    } catch (ex) {
      // parse the 'at position X' string
      let matches = ex.message.match(/at position (\d+)/)
      if (matches) {
        let position = matches[1]
        parsedJSON.atPosition = position
      }
      parsedJSON.invalidJSONMessage = ex.message
    } finally {
      return parsedJSON
    }
  },
  constructPreviewText: (doValidateJSON, text) => {
    if (doValidateJSON) {
      let parsedJSON = Helpers.parseJSON(text)
      // yellow background with arrow pointing at the 'problem'
      if (!parsedJSON.isValidJSON) {
        parsedJSON.json = `${parsedJSON.json.substring(
          0,
          parsedJSON.atPosition
        )}<span style='background:yellow'>&#8628;</span>${parsedJSON.json.substring(
          parsedJSON.atPosition
        )}`
      }
      // we have always removed the AP XML namespace from the output
      parsedJSON.json = parsedJSON.json.replace(/apxh:/g, "")
      return parsedJSON.json
    } else {
      return text
    }
  },
  updateBrowserTabTitle: (isDashboard, newTitle) => {
    let lh = window.location.host
    let matches = {
      ".dataskrive-stage.com": "(STAGE)",
      ".dataskrive-dev.com": "(DEV)",
      ".dataskrive-qa.com": "(QA)",
      ".dataskrive-lrb.com": "(LRB)"
    }
    let runningLocal = /localhost|127.0.0.1/
    if (isDashboard) {
      newTitle = "Data Skrive Portal"
    }
    if (!!lh.match(runningLocal)) {
      document.title = `${window.location.port}-${newTitle}`
    } else {
      let found = false
      for (let n in matches) {
        if (!!lh.match(n)) {
          found = true
          document.title = `${matches[n]}-${newTitle}`
        }
      }
      if (!found) {
        //For Production
        document.title = `${newTitle}`
      }
    }
  },
  buildModelPreviewUrl: ({ narrativeId, contentId, contentType = "query" }) => {
    const previewQSParams = `narrativeid=${narrativeId}&contentid=${contentId}&contenttype=${contentType}`
    const modelPreviewUrl = `${globalSettings.apiBaseUrl}/api/narrativepreview/model?${previewQSParams}`
    return modelPreviewUrl
  },
  createAcronym: inputString =>
    // Match the alphanumeric characters (letters and numbers).
    inputString
      .match(/[A-Z0-9]/g)
      // Slice the first 5 matched characters.
      .slice(0, 5)
      // Join the characters to form the string.
      .join(""),
  addCommasToNumericString: numericString =>
    // Convert the string to a number and then format it with commas
    Number(numericString).toLocaleString(),
  renderFriendlyChatboxImageResolutionValue: imageResolution => {
    switch (imageResolution) {
      case Enums.ChatBotImageResolution.Portrait:
        return "Portrait (1024x1792)"
      case Enums.ChatBotImageResolution.Landscape:
        return "Landscape (1792x1024)"
      case Enums.ChatBotImageResolution.Square:
      default:
        return "Square (1024x1024)"
    }
  },
  renderFriendlyChatboxImageQualityValue: imageQuality => {
    switch (imageQuality) {
      case Enums.ChatBotImageQuality.HD:
        return "HD"
      case Enums.ChatBotImageQuality.Standard:
      default:
        return "Standard"
    }
  },
  renderFriendlyChatboxImageStyleValue: imageStyle => {
    switch (imageStyle) {
      case Enums.ChatBotImageStyle.Natural:
        return "Natural"
      case Enums.ChatBotImageStyle.Vivid:
      default:
        return "Vivid"
    }
  },
  renderAuditInfoMuiDataGridProCell: cell => {
    let created
    let modified
    created = cell.row?.auditInfo ? (
      <span>
        created{" "}
        <TimeAgo
          date={cell.row.auditInfo.createdOn}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.row.auditInfo.createdOn
          )}
        />{" "}
        by {cell.row.auditInfo.creator.username}
      </span>
    ) : (
      ""
    )
    modified = cell.row?.auditInfo ? (
      <span>
        modified{" "}
        <TimeAgo
          date={cell.row.auditInfo.modifiedOn}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.row.auditInfo.modifiedOn
          )}
        />{" "}
        by {cell.row.auditInfo.modifier.username}
        <br />
      </span>
    ) : (
      ""
    )
    return (
      <div>
        {modified}
        {created}
      </div>
    )
  },
  renderAuditInfoMuiDataGridProAffiliateCell: cell => {
    let created
    let modified
    created = cell.row?.createdDateTime ? (
      <span>
        created{" "}
        <TimeAgo
          date={cell.row.createdDateTime}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.row.createdDateTime
          )}
        />{" "}
        by {cell.row.createdByAccountId}
      </span>
    ) : (
      ""
    )
    modified = cell.row?.modifiedDateTime ? (
      <span>
        modified{" "}
        <TimeAgo
          date={cell.row.modifiedDateTime}
          title={Helpers.prettyDateTimeinPacificTimeZone(
            cell.row.modifiedDateTime
          )}
        />{" "}
        by {cell.row.modifiedByAccountId}
        <br />
      </span>
    ) : (
      ""
    )
    return (
      <div>
        {modified}
        {created}
      </div>
    )
  },
  turnRichObjectIntoFlatNameValuePairArray: obj => {
    function turnRichToFlat(obj, results = [], comboKey) {
      if (typeof obj !== "object" || obj === null) {
        results.push({ id: results.length + 1, name: comboKey, value: obj })
        return results
      }
      for (const [k, v] of Object.entries(obj)) {
        const ck = comboKey
          ? `${comboKey.toLowerCase()}.${k.toLowerCase()}`
          : k.toLowerCase()
        turnRichToFlat(v, results, ck)
      }
      return results
    }
    return turnRichToFlat(obj)
  }
}
